import type { Database } from "../types/database";
import { createClient } from "@supabase/supabase-js";

const supabaseUrl = process.env.NEXT_PUBLIC_SUPABASE_URL;
const supabaseAnonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY;

let client: ReturnType<typeof createClient<Database>> | undefined;

export function supabaseBrowserClient() {
  if (client) {
    return client;
  }
  client = createClient<Database>(supabaseUrl, supabaseAnonKey);
  return client;
}
